<template>
  <div class="drop-down">
    <label :class="[required ? 'required' : false]" v-if="label">
      {{ label }}
    </label>
    <div class="ADrop-Down">
      <button
        :id="id"
        type="button"
        class="ADrop-Down__button d-flex align-items-center"
        :class="[
          show ? 'show' : 'hide',
          computedValue ? 'selected' : 'dntSelected'
        ]"
      >
        <AIcon
          class="ADrop-Down__button__prepend-icon"
          v-if="prependIcon"
          width="18"
          height="15"
          :name="prependIcon"
          :icon-stroke="`var(${[show || computedValue ? '--th-orange' : '--th-gray']})`"
        />
        {{ computedSelected || buttonText || $t("select") }}
        <AIcon
          class="append-arrow ml-auto"
          width="20"
          height="19"
          name="arrow-down"
        />
      </button>
      <transition name="fadeHeight" mode="in-out">
        <div
          v-show="show"
          class="ADrop-Down__menu"
          :class="[show ? 'show' : 'hide']"
        >
          <perfect-scrollbar>
            <a
              @click="computedSelected = v"
              class="d-flex align-items-center"
              :class="[computedValue === v.value ? 'active' : false]"
              v-for="(v, k) in options"
              :data-value="v.value"
              :key="k"
            >
              <AIcon
                icon-stroke="var(--th-gray)"
                width="12"
                height="12"
                name="arrow-right"
              />
              <span>{{ v.label || v.value }}</span>
            </a>
          </perfect-scrollbar>
        </div>
      </transition>
    </div>
    <transition name="fade">
      <small
        v-if="invalidFeedback || description"
        :id="'area-' + id"
        :class="[invalidFeedback ? 'danger-color' : false]"
      >
        {{ invalidFeedback || description }}
      </small>
    </transition>
  </div>
</template>

<script>
import { inputProps } from "./input-props";

export default {
  name: "ADropDown",
  props: {
    ...inputProps,
    value: [String, Number],
    label: String,
    buttonText: String,
    prependIcon: String,
    options: {
      type: Array,
      default: Array
    }
  },
  data() {
    return {
      id: "drop-down_" + this.uid + Date.now() + this._uid,
      show: false,
      selected: {}
    };
  },
  computed: {
    computedSelected: {
      get() {
        return this.selected?.label;
      },
      set(val) {
        this.$emit("update:both", val);
        this.$emit("update:value", val.value);
        this.$emit("clicked", val);
      }
    },
    computedValue(d) {
      d.selected = d.options.find(v => v.value === d.value);
      return this.value;
    }
  },
  mounted() {
    document.addEventListener("click", this.toggle);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.toggle);
  },
  methods: {
    toggle(e) {
      if (e.target.id === this.id) this.show = !this.show;
      else this.show = false;
    }
  }
};
</script>

<style scoped lang="scss">
.drop-down {
  margin-bottom: var(--th-inputs-margin-buttom);
}

.ADrop-Down {
  position: relative;
  border: 4px solid rgba(135, 149, 168, 10%);
  border-radius: 10px;
  background: #fff;
  &__button {
    padding: 9.5px 11px;
    width: 100%;
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
    color: var(--th-gray);
    text-align: left;
    font-size: 0.875rem;
    &.selected {
      color: var(--th-black);
    }
    .append-arrow {
      transition: all 300ms;
    }

    &.show {
      .append-arrow {
        transform: rotate(-180deg);
      }
    }

    &__prepend-icon {
      margin-right: 0.8125rem;
    }

    svg {
      pointer-events: none;
    }
  }

  &__menu {
    min-width: 100%;
    padding: 0 9px;
    .ps {
      max-height: 200px;
      a {
        //white-space: nowrap;
        color: var(--th-blue-dark);
        cursor: pointer;
        width: 100%;
        text-decoration: none;
        span {
          display: block;
          border-bottom: 0.5px dashed var(--th-gray);
          width: 100%;
          padding: 5px 0;
          font-size: 0.875rem;
          line-height: 1.0625rem;
          font-weight: 400;
        }
        svg {
          display: block;
          margin-right: 10px;
        }

        &:first-of-type {
          padding-top: 10px;
        }

        &:last-of-type {
          padding-bottom: 10px;
          span {
            border-bottom: none;
          }
        }
      }
    }
  }
}
</style>
